<template>
	<div class="flex flex-col text-xl gap-y-2 md:pt-12">
		<h1 class="text-4xl font-bold">
			{{ status }}
		</h1>
		<h3>
			Internal server error.
		</h3>
		<div v-if="isErrorPage">
			Sorry for the issue. Please <a
				href="/"
				class="text-green-500 cursor-pointer hover:text-purple-500"
			>
				return
			</a>
			to the home page.
		</div>
		<div v-else>
			Sorry for the issue.  Please
			<a
				href="#"
				:click="refreshPage"
				class="text-green-500 cursor-pointer hover:text-purple-500"
			>
				reload
			</a>
			the page or try again later.
		</div>
		<p>
			If the problem persists, please
			<router-link
				:to="{ name: 'contact' }"
				class="text-green-500 cursor-pointer hover:text-purple-500"
			>
				let us know.
			</router-link>
		</p>
	</div>
</template>

<script async>
import { logError } from '@/utils/error-handling.js'

export default {
	props: {
		status: {
			type: [ String, Number ],
			required: true
		}
	},
	computed: {
		isErrorPage() {
			return this.$route.name === 'error'
		}
	},
	methods: {
		refreshPage() {
			try {
				window.location.reload()
			} catch (e) {
				logError(e)
			}
		}
	}
}
</script>
